import styled, { ThemeProvider } from 'styled-components'

import colors from '../style/colors'
import React from 'react'

const ButtonElement = styled.button`
  background: ${(props) => props.theme.background};
  border: 2px solid ${(props) => props.theme.border};
  color: ${(props) => props.theme.textColor};
  width: ${(props) => props.width || 'inherit'};
  height: ${(props) => props.height || '42px'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${(props) => !props.width && 'min-width: 244px;'}
  font-size: 15px;
  text-shadow: 3px 5px 6px rgba(0, 0, 0, 0.52);
  letter-spacing: 1.65px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
  ${(props) => props.curved && 'border-radius: 4px;'}
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    !props.retainColor &&
    `
    &:hover {
      color: ${(props) => props.theme.hoverTextColor};
      background: ${(props) => props.theme.hoverBackground};
      border-color: ${(props) => props.theme.hoverBorderColor};
      text-shadow: none;
    }
  `}
  ${(props) =>
    props.showSpinner &&
    `
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    position: relative;
    font-size: 0;
    ${!props.retainColor && 'background: #fff'};
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border-top: 2px solid ${props.retainColor ? 'white' : '#831B21'};
      border-right: 2px solid transparent;
      animation: spinner .6s linear infinite;
    }
  `}
`

export default function (props) {
  // Base theme
  const theme = {
    background: colors.primary,
    border: colors.primary,
    textColor: colors.accentLight,
    hoverTextColor: colors.primary,
    hoverBackground: 'transparent',
    hoverBorderColor: colors.primary
  }

  // Inverted theme
  if (props.inverted) {
    theme.background = 'transparent'
    theme.textColor = colors.accentLight
    theme.hoverBackground = colors.primary
    theme.hoverTextColor = colors.accentLight
  }

  if (props.variant === 'success') {
    theme.background = colors.success
    theme.hoverBackground = colors.success
    theme.border = colors.success
    theme.textColor = colors.accentLight
    theme.hoverTextColor = colors.accentLight
  }

  // Manually set props
  Object.keys(props).forEach((key) => {
    if (theme[key]) {
      theme[key] = props[key]
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <ButtonElement {...props}>{props.children}</ButtonElement>
    </ThemeProvider>
  )
}
