import React from 'react'
import styled from 'styled-components'
import colors from '../style/colors'
import { svgPathData } from '@fortawesome/free-solid-svg-icons/faCaretDown'

const FieldLabel = styled.label`
  position: absolute;
  line-height: 16px;
  height: 16px;
  display: block;
  z-index: 0;
  left: 16px;
  top: 50%;
  margin-top: -7px;
  color: #727272;
  transition: 0.3s;
  opacity: 0;
  font-size: 12px;
  transform: translate3d(0, -14px, 0);
  ${(props) => props.isFloating && 'opacity: 1'};
`

const FieldError = styled.div`
  color: ${colors.error};
`

const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  ${FieldError} {
    text-align: left;
    margin-top: 6px;
    margin-left: 2px;
  }

  ${(props) =>
    props.type === 'select' &&
    `
    position: relative;
    &::after {
      content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 720"><path d="${svgPathData}" ></path></svg>');
      position: absolute;
      right: 12px;
      top: 50%;
      margin-top: -9px;
    }
  `}

  .StripeElement > * {
    top: 50%;
    transform: translate3d(0, -10px, 0);
  }

  .ElementsApp input {
    font-size: 40px;
  }
`

const FieldElem = styled.input`
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 0 16px;
  height: 56px;
  line-height: 56px;
  background: white;
  width: 100%;
  outline: 0;
  font-size: 16px;
  background: transparent;
  position: relative;
  z-index: 1;
  transition: 0.3s;
  ${(props) =>
    props.as === 'select' &&
    `
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  -ms-appearance: none;
	  -o-appearance: none;
    appearance: none;
  `}
  ${(props) =>
    props.labelIsFloating &&
    `
    padding-top: 18px;
  `}
  ${(props) =>
    props.labelIsFloating && props.as === 'select' && 'padding-top: 8px;'}
`

export class Field extends React.Component {
  constructor(props) {
    super()
    this.props = props
    this.state = {
      labelIsFloating: !!props.value || props.alwaysFloatLabel
    }
  }

  handleChange(e) {
    if (e && e.target) {
      this.setState({
        labelIsFloating: !!e.target.value || this.props.alwaysFloatLabel
      })
      if (this.props.onChange) {
        this.props.onChange(e)
      }
    }
  }

  render() {
    const { name, children, error, label, onChange, ...props } = this.props
    return (
      <FieldContainer type={props.as}>
        <div style={{ position: 'relative' }}>
          <FieldLabel isFloating={this.state.labelIsFloating}>
            {label}
          </FieldLabel>
          <FieldElem
            {...props}
            onChange={(e) => this.handleChange(e)}
            labelIsFloating={this.state.labelIsFloating}
          >
            {children}
          </FieldElem>
        </div>
        {error && <FieldError>{error.message}</FieldError>}
      </FieldContainer>
    )
  }
}
